$body-bg: #fff;
$theme-colors: (
    primary: $indigo,
    info: $pink
);

$font-weight-bold: 600;
$btn-font-weight: 400;
$btn-padding-y-lg: .75rem;
$btn-padding-x-lg: 1.75rem;
$btn-border-radius-lg: .125rem;

$navbar-brand-font-size: 1.6rem;