/*
Copyright 2018 Benchmark Software
All rights reserved
*/

@import '~bootstrap-material-design/scss/variables/colors';
@import 'variables';
@import '~bootstrap-material-design/scss/core';

.btn.btn-lg.btn-raised {
    font-size: 1rem;
    padding: $btn-padding-y-lg $btn-padding-x-lg;
}

body {
    padding-top: 3.5rem
}

#hero {
    color: #fff;
    background: linear-gradient(to right top, $indigo-900, $blue-900);
    overflow: hidden;
    
    @include media-breakpoint-up(lg) {
        .container {
            position: relative;

            &::before {
                content: ' ';
                display: block;
                position: absolute;
                bottom: 0;
                left: 65%;
                width: 779px;
                height: 683px;
                max-height: calc(100% - #{map-get($spacers, 5)});
                background: #fff url(../img/responses.png) top 25px left 20px no-repeat;
                box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
                opacity: 0;
                pointer-events: none;
                transform: translate(3rem, 0);
                transition: opacity 1s, transform 1s;
                @include border-top-radius(0.125rem);
                
                @include media-breakpoint-up(xl) {
                    transform: translate(0, 3rem);
                    left: 55%;
                }
            }
        }

        &.show-responses .container::before {
            opacity: 1;
            transform: translate(0, 0);
        }
    }
}

#intro {
    position: relative;
    
    &::before {
        content: ' ';
        position: absolute;
        display: block;
        top: -10px;
        left: 0;
        right: 0;
        height: 10px;
        background: linear-gradient(to top, rgba(0, 0, 0, 0.14), transparent);
        pointer-events: none;
    }
    
    @include media-breakpoint-up(lg) {
        .container {
            max-width: map-get($container-max-widths, md);
        }
    }
}

#steps {
    background: #fafafa;
    overflow: hidden;
    
    .step {
        text-align: center;
        margin-bottom: 1rem;
        
        .icon {
            position: relative;
            width: 4rem;
            height: 4rem;
            margin: 1rem auto;
            color: #fff;
            background: $indigo;
            border-radius: 100%;
            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
            
            svg {
                display: block;
                width: 4rem;
                height: 4rem;
                padding: .75rem;
                fill: currentColor;
            }
            
            .number {
                position: absolute;
                top: 0rem;
                left: -1.5rem;
                width: 2rem;
                height: 2rem;
                line-height: 2rem;
                text-align: center;
                font-weight: $font-weight-bold;
                background-color: $pink;
                border-radius: 100%;
                box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
            }
        }
    }
}

#price {
    
    .row > div {
        text-align: center;
        
        > div {
            display: inline-block;
        }
        
        &:first-child {
            @include media-breakpoint-down(sm) {
                padding-bottom: 2rem;
            }
            @include media-breakpoint-up(md) {
                border-right: 1px solid $gray-lighter;
            }
        }
        
        @include media-breakpoint-down(sm) {
            &:last-child {
                background: $indigo;
                color: #fff;
                padding-top: 2rem;
                padding-bottom: 2rem;
            }
        }
    }
    
    ul {
        display: inline-block;
        width: auto;
        text-align: left;
        margin: 0 auto 1rem;
        
        li {
            line-height: 2rem;
            
            &::before {
                $svgcolor: unquote("%23" + str_slice(theme-color('primary') + unquote(""), 2));
                content: ' ';
                display: inline-block;
                vertical-align: text-bottom;
                width: 1.5rem;
                height: 1.5rem;
                margin-right: 0.5rem;
                background: center no-repeat url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M0 0h24v24H0z' fill='none'/%3E %3Cpath d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z' fill='#{$svgcolor}'/%3E %3C/svg%3E ");
                background-size: contain;
            }
        }
    }
}

#footer {
    color: #fff;
    
    a {
        color: #fafafa;
    }
}

#footer-main {
    background: $indigo;
}

#footer-bottom {
    color: #fff;
    background: $indigo-800;
}